import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { useTranslation } from "react-i18next";
import { animated, config, useSpring } from "@react-spring/web";
import PieChart from "./PieChart";
import languageColors from "../languageColors";
import colors from "../colors";

const fadeIn = keyframes`
 0% { opacity: 0; }
 20% { opacity: 0; }
 100% { opacity: 1; }
`;

const Background = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ page }) => {
    switch (page) {
      case 0:
        return colors.page1L;
      case 1:
        return colors.page2L;
      case 2:
        return colors.page3L;
      case 3:
        return colors.page4;
      case 4:
        return colors.page5B;
      default:
        return colors.page5T;
    }
  }};
  background-image: ${({ page }) =>
    page === 0 ? `url(${require("../assets/images/minitile.png")})` : "none"};
  width: ${({ page }) => {
    switch (page) {
      case 0:
        return "100%";
      case 1:
        return "calc(100% / 3 * 1)";
      case 2:
        return "calc(100% / 3 * 2)";
      case 3:
        return "0%";
      case 4:
        return "100%";
      default:
        return "100%";
    }
  }};
  height: 100%;
  transition: width 0.3s ease-in-out,
    background-color 1s cubic-bezier(0.09, 0.7, 0.25, 0.95);
  @media (min-width: 640px) {
    background-image: ${({ page }) =>
      page === 0 ? `url(${require("../assets/images/tile.png")})` : "none"};
  }
`;

const TitleLogo = styled(animated.div)`
  font-family: "Caveat", cursive;
  font-size: 3rem;
  color: rgba(255, 255, 255, 0.8);
  text-shadow: 5px 5px 8px #444444;
  animation-name: ${fadeIn};
  animation-duration: 1.5s;

  @media (min-width: 640px) {
    font-size: 8rem;
  }
`;

const ThirdPage = styled.div`
  width: 100%;
  height: 80vh;
  position: relative;
  transform: translateX(25%);

  @media (min-width: 640px) {
    display: flex;
    height: auto;
    justify-content: center;
    transform: translateX(0%);
  }
`;

const Description = styled.div`
  color: white;
  font-size: 0.8rem;
  text-align: center;

  @media (min-width: 640px) {
    display: none;
  }
`;

const Title = styled.h2`
  display: none;

  @media (min-width: 640px) {
    display: block;
    font-size: 2rem;
    color: white;
    margin: 1.6rem 0;
    text-align: center;
    text-shadow: 1px 1px rgba(50, 50, 50, 0.3);
    animation-name: ${fadeIn};
    animation-duration: 1.2s;
  }
`;

const Subtitle = styled.p`
  padding: 1rem 0;
  text-align: center;
`;

const ChartContainer = styled.div`
  width: 100%;
  height: 40vh;
  padding-top: 2rem;

  @media (min-width: 640px) {
    width: calc(100% / 3 * 2);
    height: 70vh;
    padding-top: 0;
    left: 0;
    transform: translateX(0%);
  }
`;

const Table = styled.table`
  width: 80%;
  margin: 0 auto;
`;

const TableCell = styled.td`
  width: ${({ dot }) => (dot ? "10%" : "45%")};
  padding: 0.3rem 0;
  text-align: ${({ value }) => (value ? "right" : "justify")};
`;

const Dot = styled.div`
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 0.8rem;
  background-color: ${({ color }) => color};
`;

const LeftContainer = ({ page, languages }) => {
  const { t } = useTranslation();

  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    delay: 1000,
    config: config.molasses,
  }));

  const calc = (x, y) => [
    x - window.innerWidth / 2,
    y - window.innerHeight / 2,
  ];

  const trans1 = (x, y) => `translate3d(${x / 12}px, ${y / 12}px, 0)`;
  const trans2 = (x, y) => `translate3d(${x / 10}px, ${y / 10}px, 0)`;

  const [total, setTotal] = useState(0);

  const arr = Object.entries(languages);
  arr.sort((a, b) => b[1] - a[1]);

  const sumTotal = () => {
    let sum = 0;
    for (let item of Object.values(languages)) {
      sum += item;
    }
    setTotal(sum);
  };

  useEffect(() => {
    sumTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languages]);

  window.addEventListener("resize", () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });

  return (
    <Background
      page={page}
      onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
    >
      {page === 0 && (
        <>
          <TitleLogo style={{ transform: props.xy.interpolate(trans1) }}>
            Minho Yun`s
          </TitleLogo>
          <TitleLogo style={{ transform: props.xy.interpolate(trans2) }}>
            Portfolio
          </TitleLogo>
        </>
      )}
      {page === 1 && <Title>{t("SECOND_TITLE")}</Title>}
      {page === 2 && (
        <ThirdPage>
          <ChartContainer>
            <PieChart languages={languages} />
          </ChartContainer>
          <Description>
            <Subtitle>{t("TOTAL_LANGUAGES", { total })}</Subtitle>
            <Table>
              <tbody>
                {arr.map(([key, value]) => (
                  <tr key={key}>
                    <TableCell dot={true}>
                      <Dot color={languageColors[key].color} />
                    </TableCell>
                    <TableCell>{key}</TableCell>
                    <TableCell value={true}>{value}</TableCell>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Description>
        </ThirdPage>
      )}
    </Background>
  );
};

export default LeftContainer;
