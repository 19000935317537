import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

const globalStyles = createGlobalStyle`
    ${reset}
    html {
        width: 100%;
        height: 100%;
    }
    body {
        width: 100%;
        height: 100%;
        overflow: hidden;
        font-family: 'Noto Sans JP', 'Lato', 'Noto Sans KR', sans-serif;
    }
`;

export default globalStyles;
