import React from "react";
import styled from "styled-components";
import colors from "../colors";

const Container = styled.div`
  position: fixed;
  width: 2rem;
  height: 10rem;
  top: 50%;
  transform: translateY(-5rem);
  display: flex;
  align-items: center;
  z-index: 50;

  @media (min-width: 640px) {
    width: 5rem;
  }
`;

const PageContainer = styled.div`
  width: 5rem;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const Dot = styled.div`
  background-color: ${({ current }) =>
    current === true ? colors.dot : colors.gray};
  width: ${({ current }) => (current === true ? "1rem" : "0.7rem")};
  height: ${({ current }) => (current === true ? "1rem" : "0.7rem")};
  opacity: 0.8;
  border-radius: 50%;
  cursor: pointer;
`;

const PageNavigation = ({ page, setPage }) => {
  return (
    <Container>
      <PageContainer>
        <Dot current={page === 0 ? true : false} onClick={() => setPage(0)} />
        <Dot current={page === 1 ? true : false} onClick={() => setPage(1)} />
        <Dot current={page === 2 ? true : false} onClick={() => setPage(2)} />
        <Dot current={page === 3 ? true : false} onClick={() => setPage(3)} />
        <Dot current={page === 4 ? true : false} onClick={() => setPage(4)} />
      </PageContainer>
    </Container>
  );
};

export default PageNavigation;
