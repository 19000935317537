const color = {
  gray: "#f7f1e3",
  darkBlack: "#111111",
  lightBlack: "#232526",
  dot: "#000000",
  page1L: "#212529",
  page1R: "#343a40",
  page2L: "#343a40",
  page2R: "#495057",
  page3L: "#495057",
  page3R: "#6c757d",
  page4: "#495057",
  page5B: "#212529",
  page5T: "#343a40",
};

export default color;
