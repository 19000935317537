import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { keyframes } from "styled-components";
import WorkCarousel from "../components/WorkCarousel";
import languageColors from "../languageColors";
import colors from "../colors";

const fadeIn = keyframes`
 0% { opacity: 0; }
 20% { opacity: 0; }
 100% { opacity: 1; }
`;

const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ page }) => {
    switch (page) {
      case 0:
        return colors.page1R;
      case 1:
        return colors.page2R;
      case 2:
        return colors.page3R;
      case 3:
        return colors.page4;
      default:
        return colors.page5T;
    }
  }};
  width: ${({ page }) => {
    switch (page) {
      case 0:
        return "0";
      case 1:
        return "calc(100% / 3 * 2)";
      case 2:
        return "calc(100% / 3 * 1)";
      case 3:
        return "100%";
      case 4:
        return "0";
      default:
        return "0";
    }
  }};
  height: 100%;
  transition: width 0.3s ease-in-out,
    background-color 1s cubic-bezier(0.09, 0.7, 0.25, 0.95);
  display: flex;
  justify-content: center;

  @media (min-width: 640px) {
    width: ${({ page }) => {
      switch (page) {
        case 0:
          return "0";
        case 1:
          return "calc(100% / 3 * 2)";
        case 2:
          return "calc(100% / 3 * 1)";
        case 3:
          return "100%";
        case 4:
          return "0";
        default:
          return "0";
      }
    }};
  }
`;

const SecondPage = styled.div`
  width: 70%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 640px) {
  }
`;

const ThirdPage = styled.div`
  width: 100%;
  padding: 0 1rem;
  height: 80vh;
  position: relative;
  transform: translateX(25%);
  display: none;

  @media (min-width: 640px) {
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    transform: translateX(0%);
  }
`;

const FourthPage = styled.div`
  width: 70%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 640px) {
    width: 80%;
  }
`;

const Title = styled.h2`
  display: none;

  @media (min-width: 640px) {
    display: block;
    font-size: 2rem;
    color: white;
    margin: 1.6rem 0;
    text-align: center;
    text-shadow: 1px 1px rgba(50, 50, 50, 0.3);
    animation-name: ${fadeIn};
    animation-duration: 1.2s;
  }
`;

const Subtitle = styled.p`
  padding: 1rem 0;
  text-align: center;
  animation-name: ${fadeIn};
  animation-duration: 1.5s;
`;

const Introduction = styled.p`
  color: white;
  line-height: 150%;
  font-size: 0.8rem;
  animation-name: ${fadeIn};
  animation-duration: 2s;

  @media (min-width: 640px) {
    font-size: 1rem;
  }
`;

const Description = styled.div`
  display: none;
  color: white;

  @media (min-width: 640px) {
    display: block;
  }
`;

const Table = styled.table`
  width: 80%;
  margin: 0 auto;
  animation-name: ${fadeIn};
  animation-duration: 2s;
`;

const TableCell = styled.td`
  width: ${({ dot }) => (dot ? "10%" : "45%")};
  padding: 0.3rem 0;
  text-align: ${({ value }) => (value ? "right" : "justify")};
`;

const Dot = styled.div`
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 0.8rem;
  background-color: ${({ color }) => color};
`;

const WorkTitle = styled.div`
  color: white;
  font-size: 1rem;
  padding: 1rem;
  text-shadow: 1px 1px rgba(50, 50, 50, 0.3);
  animation-name: ${fadeIn};
  animation-duration: 0.8s;

  @media (min-width: 640px) {
    font-size: 1.5rem;
    text-shadow: 2px 2px rgba(50, 50, 50, 0.3);
  }
`;

const WorkDescription = styled.div`
  color: white;
  font-size: 0.8rem;
  text-align: center;
  animation-name: ${fadeIn};
  animation-duration: 0.8s;

  @media (min-width: 640px) {
    font-size: 1rem;
  }
`;

const RightContainer = ({ page, languages, works }) => {
  const { t } = useTranslation();

  const [total, setTotal] = useState(0);

  const [value, setValue] = useState(0);

  let arr = Object.entries(languages);
  arr.sort((a, b) => b[1] - a[1]);

  const sumTotal = () => {
    let sum = 0;
    for (let item of Object.values(languages)) {
      sum += item;
    }
    setTotal(sum);
  };

  useEffect(() => {
    sumTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languages]);

  useEffect(() => {
    if (page === 4) {
      setValue(0);
    }
  }, [page]);

  window.addEventListener("resize", () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });

  return (
    <Background page={page}>
      {page === 1 && (
        <SecondPage>
          <Introduction>{t("INTRO")}</Introduction>
        </SecondPage>
      )}
      {page === 2 && (
        <ThirdPage>
          <Title>{t("THIRD_TITLE")}</Title>
          <Description>
            <Subtitle>{t("TOTAL_LANGUAGES", { total })}</Subtitle>
            <Table>
              <tbody>
                {arr.map(([key, value]) => (
                  <tr key={key}>
                    <TableCell dot={true}>
                      <Dot color={languageColors[key].color} />
                    </TableCell>
                    <TableCell>{key}</TableCell>
                    <TableCell value={true}>{value}</TableCell>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Description>
        </ThirdPage>
      )}
      {page === 3 && works && (
        <FourthPage>
          <WorkCarousel works={works} setValue={setValue} />
          <WorkTitle>{t(works[value]?.title)}</WorkTitle>
          <WorkDescription>{t(works[value]?.intro)}</WorkDescription>
        </FourthPage>
      )}
    </Background>
  );
};

export default RightContainer;
