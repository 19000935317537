import React, { useRef, useState } from "react";
import { Pie } from "react-chartjs-2";
import "chartjs-plugin-style";
import languageColors from "../languageColors";

const PieChart = ({ languages }) => {
  const chart = useRef(null);
  const [highlightColor, setHighlightColor] = useState();

  const colorsArr = [];
  Object.keys(languages).map((language) =>
    colorsArr.push(languageColors[language].color)
  );

  const data = {
    labels: Object.keys(languages),
    layout: {
      padding: "10px",
    },
    datasets: [
      {
        data: Object.values(languages),
        backgroundColor: colorsArr,
        borderWidth: 0,
        shadowOffsetX: 3,
        shadowOffsetY: 3,
        shadowBlur: 10,
        shadowColor: "rgba(0, 0, 0, 0.3)",
        hoverInnerGlowWidth: 10,
        hoverInnerGlowColor: highlightColor,
        hoverOuterGlowWidth: 10,
        hoverOuterGlowColor: highlightColor,
      },
    ],
  };

  return (
    <Pie
      ref={chart}
      data={data}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 1000,
        legend: false,
        layout: {
          padding: 10,
        },
        onHover: (e) => {
          const item = chart.current.chartInstance.getElementAtEvent(e);
          if (item.length) {
            setHighlightColor(colorsArr[item[0]._index]);
          }
        },
      }}
    />
  );
};

export default PieChart;
