import React, { useState } from "react";
import { Globe, Menu } from "react-feather";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import { Logo } from "../components/Icons";

const Navbar = styled.div`
  position: fixed;
  width: 100%;
  height: 5rem;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 50;
`;

const NavItem = styled(animated.div)`
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;

const LanguageList = styled.div`
  position: absolute;
  top: 1.5rem;
  left: -13rem;
  width: 12rem;
  height: 5rem;
  display: flex;
  flex-direction: column;
  opacity: ${({ toggled }) => (!toggled ? "0" : "1")};
  transform: ${({ toggled }) =>
    !toggled ? "translateX(0)" : "translateX(17rem)"};
  transition: all 0.5s cubic-bezier(0.94, 0.22, 0.31, 0.91);

  @media (min-width: 640px) {
    flex-direction: row;
    top: 0;
    left: -12rem;
  }
`;

const LanguageItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 4rem;
  height: 5rem;
  color: white;
  cursor: pointer;

  &:hover {
    opacity: 50%;
  }
`;

const GlobeIcon = styled(Globe)`
  cursor: pointer;
`;

const MenuIcon = styled(Menu)`
  cursor: pointer;
`;

const Header = ({ openMenu, changeLanguage }) => {
  const [toggled, setToggled] = useState(false);

  const [leftItemProps, setLeftItemOnMouse] = useSpring(() => ({
    opacity: 1,
    from: {
      opacity: 0,
    },
    config: {
      tension: 300,
      friction: 10,
    },
  }));

  const [rightItemProps, setRightItemOnMouse] = useSpring(() => ({
    opacity: 1,
    from: {
      opacity: 0,
    },
  }));

  const logoItemProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    delay: 300,
    config: { duration: 1000 },
  });

  const itemOnMouseEnter = (fn) => {
    let degree = 0;
    if (fn === setLeftItemOnMouse) {
      degree = 30;
    } else {
      degree = -10;
    }
    fn({ scale: 1.2, opacity: 0.5, transform: `rotate(${degree}deg)` });
  };

  const itemOnMouseLeave = (fn) => {
    fn({ scale: 1, opacity: 1, transform: "rotate(0deg)" });
  };

  const toggleLanguageList = () => {
    setToggled(!toggled);
  };

  return (
    <Navbar>
      <NavItem
        style={leftItemProps}
        onClick={toggleLanguageList}
        onMouseEnter={() => itemOnMouseEnter(setLeftItemOnMouse)}
        onMouseLeave={() => itemOnMouseLeave(setLeftItemOnMouse)}
      >
        <GlobeIcon size={40} />
      </NavItem>
      <LanguageList toggled={toggled}>
        <LanguageItem onClick={changeLanguage.bind(this, "ja")}>
          日本語
        </LanguageItem>
        <LanguageItem onClick={changeLanguage.bind(this, "en")}>
          English
        </LanguageItem>
        <LanguageItem onClick={changeLanguage.bind(this, "ko")}>
          한국어
        </LanguageItem>
      </LanguageList>
      <NavItem style={logoItemProps}>
        <Logo />
      </NavItem>
      <NavItem
        style={rightItemProps}
        onClick={openMenu}
        onMouseEnter={() => itemOnMouseEnter(setRightItemOnMouse)}
        onMouseLeave={() => itemOnMouseLeave(setRightItemOnMouse)}
      >
        <MenuIcon size={40} />
      </NavItem>
    </Navbar>
  );
};

export default Header;
