import React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  min-width: ${({ size }) => size};
  cursor: ${({ clickable }) => (clickable === true ? "pointer" : "default")};

  &:hover {
    opacity: ${({ clickable }) => clickable && "50%"};
  }
`;

export const Logo = () => (
  <Container size={"60%"} clickable={false}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.06 163.48">
      <defs>
        <clipPath id="a" transform="translate(2.94 -33.1)">
          <ellipse cx="67.6" cy="93.7" rx="67.6" ry="93.7" fill="none" />
        </clipPath>
      </defs>
      <g clipPath="url(#a)">
        <path
          d="M2.47,37.59l64.7,78.78M60.69,78.76h0m72-41.22L68,116.37m-.79,0L68,196.5M28.35,69.25v109.6m79-110.15V178.3"
          transform="translate(2.94 -33.1)"
          fill="none"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="14"
        />
      </g>
    </svg>
  </Container>
);

export const Close = () => (
  <Container size={"35%"} clickable={true}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 185.24 185.24">
      <line
        x1="7.07"
        y1="7.07"
        x2="178.17"
        y2="178.17"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="20"
      />
      <line
        x1="178.17"
        y1="7.07"
        x2="7.07"
        y2="178.17"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="20"
      />
    </svg>
  </Container>
);

export const Github = ({ onClick }) => (
  <Container size={"10%"} clickable={true} onClick={onClick}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 220.31 214.88">
      <path
        d="M110.15,0A110.16,110.16,0,0,0,75.34,214.68c5.5,1,7.28-2.39,7.28-5.29V188.88c-30.65,6.66-37-13-37-13-5-12.73-12.23-16.12-12.23-16.12-10-6.84.76-6.69.76-6.69C45.18,153.84,51,164.42,51,164.42c9.82,16.84,25.77,12,32.06,9.16,1-7.12,3.83-12,7-14.73-24.46-2.8-50.18-12.24-50.18-54.44A42.63,42.63,0,0,1,51.21,74.84c-1.14-2.78-4.91-14,1.08-29.15,0,0,9.25-3,30.3,11.29a104.37,104.37,0,0,1,55.16,0C158.78,42.73,168,45.69,168,45.69c6,15.17,2.22,26.38,1.09,29.15a42.55,42.55,0,0,1,11.33,29.57c0,42.31-25.76,51.62-50.29,54.35,4,3.42,7.55,10.12,7.55,20.4v30.23c0,2.92,1.77,6.37,7.36,5.28A110.17,110.17,0,0,0,110.15,0Z"
        fill="#fff"
      />
    </svg>
  </Container>
);

export const LinkedIn = ({ onClick }) => (
  <Container size={"10%"} clickable={true} onClick={onClick}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 209.81 209.81">
      <path
        d="M166.1,0H43.71A43.71,43.71,0,0,0,0,43.71V166.1a43.72,43.72,0,0,0,43.71,43.71H166.1a43.72,43.72,0,0,0,43.71-43.71V43.71A43.71,43.71,0,0,0,166.1,0ZM69.94,166.1H43.71V69.94H69.94ZM56.82,58.85a15.42,15.42,0,1,1,15.3-15.42A15.37,15.37,0,0,1,56.82,58.85Zm118,107.25H148.61v-49c0-29.45-35-27.22-35,0v49H87.42V69.94h26.23V85.37c12.2-22.61,61.19-24.28,61.19,21.64Z"
        fill="#fff"
      />
    </svg>
  </Container>
);

export const Wantedly = ({ onClick }) => (
  <Container size={"10%"} clickable={true} onClick={onClick}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 900 900">
      <path
        fill="#fff"
        d="M100 208.6h100l150 362.1L400 450 300 208.6h100l50 120.7 50-120.7h100L500 450l50 120.7 150-362.1h100L600 691.4H500l-50-120.7-50 120.7H300z"
      />
    </svg>
  </Container>
);

export const Mail = () => (
  <Container size={"10%"} clickable={false}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 212.49 150.51">
      <path
        d="M197.33,150.51H15.16A15.16,15.16,0,0,1,0,135.36V15.16A15.16,15.16,0,0,1,15.16,0H197.33a15.16,15.16,0,0,1,15.16,15.16v120.2A15.16,15.16,0,0,1,197.33,150.51ZM106.25,96.63,23.53,39.23l-.17,102.43H188.78V39.23ZM184.77,8.85H27.27l79,55.15S161.6,25,184.77,8.85Z"
        fill="#fff"
      />
    </svg>
  </Container>
);
