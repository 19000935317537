export default {
  ja: {
    translation: {
      TOP: "トップ",
      ABOUT: "紹介",
      SKILLS: "スキル",
      WORKS: "作品",
      CONTACT: "連絡先",
      SCROLL: "スクロール",
      SWIPE: "スワイプ",
      SECOND_TITLE: "自己紹介",
      INTRO:
        "韓国ソウル出身で、現在愛知県名古屋市に在住。韓国の高麗大学で歴史学・政治外交学を専攻し、電力会社に事務(会計)職で入社。形のある成果を残したいと思い、日本人と結婚することをきっかけに会社を辞め日本に移住。HAL名古屋の高度情報学科でプログラミングを勉強しCから始め、JavaScript・Python・Javaなどの言語を経験。人の生活に価値を付与するものを作るWebエンジニアになるために頑張っています。",
      THIRD_TITLE: "使ったことある言語",
      TOTAL_LANGUAGES: "{{total}}個のGithubリポジトリの中で",
      "Coco (Web App)": "Coco (Webアプリ)",
      "Coco (Mobile App)": "Coco (モバイルアプリ)",
      "Coco Lost Dog Support": "Coco迷子サポート",
      "Wanflix (Web App)": "Wanflix (Webアプリ)",
      "Wanflixpo (Android)": "Wanflixpo (アンドロイド)",
      "Uniwalk (Team Project)": "Uniwalk (チームプロジェクト)",
      "SNS for dogs and their lovers": "ワンちゃんと犬好きのためのSNS",
      "Lost Dog Support System of Coco": "迷子犬をサポートするシステム",
      "GraphQL API for Coco app": "CocoアプリのためのGraphQL API",
      "Movie info app": "映画情報提供アプリ",
      "Web Design for an imaginary perfume boutique":
        "仮想の香水ブティックサイト",
      "An imaginary e-Commerce site for selling customized sneakers":
        "仮想のカスタムスニーカーECサイト",
    },
  },
  en: {
    translation: {
      TOP: "TOP",
      ABOUT: "ABOUT",
      SKILLS: "SKILLS",
      WORKS: "WORKS",
      CONTACT: "CONTACT",
      SCROLL: "SCROLL",
      SWIPE: "SWIPE",
      SECOND_TITLE: "About Me",
      INTRO:
        "Born in Seoul, South Korea. And now living in Nagoya, Japan. Graduated from Korea University as a bachelor of History, Political science and International relations. After graduation, worked as an assistant manager of the accounting team at a power plant. By the willing to achieve visible results, however, determined to resign from the job and moved to Japan in order to marry my Japanese girlfriend. From HAL Nagoya, experiencing programming languages like C, JavaScript, Python, Java and so on. Studying hard to be an web engineer that creates values in people's life now.",
      THIRD_TITLE: "Languages",
      TOTAL_LANGUAGES: "From my {{total}} github repositories",
      "Coco (Web App)": "Coco (Web App)",
      "Coco (Mobile App)": "Coco (Mobile App)",
      "Coco Lost Dog Support": "Coco Lost Dog Support",
      "Wanflix (Web App)": "Wanflix (Web App)",
      "Wanflixpo (Android)": "Wanflixpo (Android)",
      "Uniwalk (Team Project)": "Uniwalk (Team Project)",
      "SNS for dogs and their lovers": "SNS for dogs and their lovers",
      "Lost Dog Support System of Coco": "Lost Dog Support System of Coco",
      "GraphQL API for Coco app": "GraphQL API for Coco app",
      "Movie info app": "Movie info app",
      "Web Design for an imaginary perfume boutique":
        "Web Design for an imaginary perfume boutique",
      "An imaginary e-Commerce site for selling customized sneakers":
        "An imaginary e-Commerce site for selling customized sneakers",
    },
  },
  ko: {
    translation: {
      TOP: "홈",
      ABOUT: "소개",
      SKILLS: "스킬",
      WORKS: "작품",
      CONTACT: "연락처",
      SCROLL: "스크롤",
      SWIPE: "스와이프",
      SECOND_TITLE: "자기소개",
      INTRO:
        "한국 서울 출신으로, 현재 일본 아이치 나고야시에 거주 중. 고려대학교에서 사학, 정치외교학을 전공 후 전력회사에 사무직(회계)으로 입사. 가시적인 성과를 내는 일을 업으로 삼고 싶어, 일본인과 결혼한 것을 계기로 퇴사 후 일본에 이주. HAL나고야 고도정보학과에서 프로그래밍을 공부하며 C언어부터 JavaScript, Python・Java 등의 언어를 경험. 사람의 생활에 있어 가치를 부여하는 것을 만들어내는 Web 엔지니어가 되기 위해 공부 중입니다.",
      THIRD_TITLE: "다루어 본 언어",
      TOTAL_LANGUAGES: "총 {{total}}개의 Github 리포지토리 중",
      "Coco (Web App)": "Coco (Web 앱)",
      "Coco (Mobile App)": "Coco (모바일 앱)",
      "Coco Lost Dog Support": "Coco 미아 서포트",
      "Wanflix (Web App)": "Wanflix (Web 앱)",
      "Wanflixpo (Android)": "Wanflixpo (안드로이드)",
      "Uniwalk (Team Project)": "Uniwalk (팀 프로젝트)",
      "SNS for dogs and their lovers":
        "강아지와 강아지를 사랑하는 사람들을 위한 SNS",
      "Lost Dog Support System of Coco": "미아가 된 강아지를 서포트하는 시스템",
      "GraphQL API for Coco app": "Coco 앱을 위한 GraphQL API",
      "Movie info app": "영화 정보 앱",
      "Web Design for an imaginary perfume boutique": "가상 향수 부띠끄 사이트",
      "An imaginary e-Commerce site for selling customized sneakers":
        "가상 커스텀 신발 판매 쇼핑 사이트",
    },
  },
};
