import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import color from "../colors";
import colors from "../colors";
import { Github, LinkedIn, Mail, Wantedly } from "../components/Icons";

const fadeIn = keyframes`
 0% { opacity: 0; }
 20% { opacity: 0; }
 100% { opacity: 1; }
`;

const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: ${colors.page5T};
  width: 100%;
  height: ${({ page }) => (page !== 4 ? "0" : "100%")};
  left: 50%;
  bottom: 0px;
  transform: translateX(-50%);
  transition: all 0.3s ease-in-out;
  box-sizing: border-box;
  padding: 0 1rem;

  @media (min-width: 640px) {
    width: calc(100% / 3);
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ImageContainer = styled.div`
  width: 70%;
  height: ${(props) => props.height}px;
  filter: grayscale(40%);
  border-radius: 100%;
  background: url(${require("../assets/images/myself.jpg")});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin: 0 auto;
  animation-name: ${fadeIn};
  animation-duration: 1.8s;
`;

const LinkContainer = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;
  margin-top: 2rem;
  animation-name: ${fadeIn};
  animation-duration: 2.3s;
`;

const EmailContainer = styled.div`
  min-width: 70%;
  display: flex;
  background: ${color.page5B};
  border-radius: 5px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 2rem;
  cursor: pointer;
  animation-name: ${fadeIn};
  animation-duration: 2.8s;

  &:hover {
    opacity: 0.5;
  }
`;

const Email = styled.span`
  margin-left: 8px;
  color: white;
  font-size: 12px;

  @media (min-width: 640px) {
    font-size: 14px;
  }
`;

const MiddleContainer = ({ page }) => {
  const [height, setHeight] = useState(0);
  const container = useRef(null);

  const handleResize = () => {
    setHeight(container?.current?.offsetWidth);
  };

  const openLink = (service) => {
    switch (service) {
      case "github":
        window.open("https://github.com/yun-mh", "_blank");
        break;
      case "linkedIn":
        window.open(
          "https://www.linkedin.com/in/minho-yun-1a232a1aa/",
          "_blank"
        );
        break;
      case "wantedly":
        window.open("https://www.wantedly.com/id/minho_yun", "_blank");
        break;
      case "mail":
        window.open("mailto:minhoyun.dev@gmail.com");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (container.current !== null) {
      handleResize();
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  window.addEventListener("resize", () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });

  return (
    <Background page={page}>
      {page === 4 && (
        <Content>
          <ImageContainer ref={container} height={height} />
          <LinkContainer>
            <Github onClick={() => openLink("github")} />
            <LinkedIn onClick={() => openLink("linkedIn")} />
            <Wantedly onClick={() => openLink("wantedly")} />
          </LinkContainer>
          <EmailContainer onClick={() => openLink("mail")}>
            <Mail />
            <Email>minhoyun.dev@gmail.com</Email>
          </EmailContainer>
        </Content>
      )}
    </Background>
  );
};

export default MiddleContainer;
