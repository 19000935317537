import axios from "axios";

const callApi = async (method, path) => {
  const baseUrl = "https://minhoyundev.pythonanywhere.com/api/v1";
  const fullUrl = `${baseUrl}${path}`;
  return axios[method](fullUrl);
};

export default {
  works: () => callApi("get", "/works/"),
};
