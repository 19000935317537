import React from "react";
import styled from "styled-components";
import { ChevronLeft, ChevronRight } from "react-feather";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CarouselLayoutContainer = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;

  .slick-track {
    display: flex;
    align-items: stretch;
  }

  .slick-slide {
    display: flex;
    align-self: stretch;
    height: unset;
    > div {
      display: flex;
      align-self: stretch;
      width: 100%;
    }
  }
`;

const Image = styled.div`
  position: relative;
  width: 50vw;
  height: 70vw;
  background: ${({ url }) => `url(${url})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  filter: grayscale(85%);
  opacity: 70%;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out,
    filter 1s cubic-bezier(0.09, 0.7, 0.25, 0.95);

  &:hover {
    filter: none;
    opacity: 100%;
  }

  &:active {
    outline: none;
  }

  @media (min-width: 640px) {
    width: 50vw;
    height: 30vw;
    background-size: cover;
  }
`;

const WorkCarousel = ({ works, setValue }) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    swipeToSlide: true,
    draggable: false,
    nextArrow: <ChevronRight color={"white"} />,
    prevArrow: <ChevronLeft color={"white"} />,
    beforeChange: (_, next) => setValue(next),
  };

  const openLink = (link) => {
    window.open(link, "_blank");
  };

  return (
    <CarouselLayoutContainer style={{ width: "80%", display: "inline-block" }}>
      <Slider {...settings}>
        {works &&
          works.map((work) => (
            <Image
              key={work.id}
              url={work?.images[0]?.image}
              onClick={() => openLink(work.github_url)}
            />
          ))}
      </Slider>
    </CarouselLayoutContainer>
  );
};

export default WorkCarousel;
