import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { useTranslation } from "react-i18next";
import { animated, useSpring } from "@react-spring/web";
import { X } from "react-feather";
import colors from "../colors";

const fadeIn = keyframes`
 0% { opacity: 0; }
 20% { opacity: 0; }
 100% { opacity: 1; }
`;

const fadeOut = keyframes`
 0% { opacity: 1; }
 20% { opacity: 1; }
 100% { opacity: 0; }
`;

const Background = styled.div`
  background: linear-gradient(45deg, ${colors.darkBlack}, ${colors.lightBlack});
  width: 100%;
  height: 100%;
  transition: all 0.5s linear;
  position: fixed;
  transform: ${({ menuOpened }) =>
    !menuOpened ? "translateX(100%)" : "translateX(0)"};
  transition: transform 0.5s cubic-bezier(0.94, 0.22, 0.31, 0.91);
  z-index: 100;
`;

const ButtonSet = styled.div`
  width: 100%;
  height: 5rem;
  background-color: transparent;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const CloseButton = styled(animated.div)`
  color: white;
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const List = styled.ul`
  width: 80%;
  height: calc(100% - 12rem);
  margin: 0 auto;
  font-size: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;

  @media (min-width: 640px) {
    font-size: 4rem;
  }
`;

const ListItem = styled.li`
  text-align: right;
  color: white;
  cursor: pointer;
  opacity: ${({ closed }) => (closed ? 0 : 1)};
  animation-name: ${({ closed }) => (closed ? fadeOut : fadeIn)};
  animation-duration: ${({ closed, item }) =>
    closed ? (5 - item) * 0.12 : 2 + item * 0.2}s;

  &:hover {
    opacity: 0.5;
  }
`;

const Footer = styled.div`
  width: 100%;
  font-size: 1rem;
  margin: 0 auto;
  position: absolute;
  bottom: 1.5rem;
`;

const FooterContent = styled.div`
  width: 80%;
  margin: 0 auto;
  color: gray;
  text-align: right;
  opacity: ${({ closed }) => (closed ? 0 : 1)};
  animation-name: ${({ closed }) => (closed ? fadeOut : fadeIn)};
  animation-duration: ${({ closed }) => (closed ? "0.2s" : "2s")};
`;

const Menu = ({ menuOpened, closeMenu, setPage }) => {
  const { t } = useTranslation();

  const [props, set] = useSpring(() => ({}));

  const [closed, setClosed] = useState(false);

  const itemOnMouseEnter = (fn) => {
    fn({
      from: {
        scale: 1,
        opacity: 1,
        transform: "rotate(0deg)",
      },
      to: {
        scale: 1.2,
        opacity: 0.5,
        transform: "rotate(180deg)",
      },
    });
  };

  const itemOnMouseLeave = (fn) => {
    fn({
      from: {
        transform: "rotate(0deg)",
      },
      to: {
        scale: 1,
        opacity: 1,
        transform: "rotate(-180deg)",
      },
    });
  };

  const itemOnClick = (fn) => {
    fn({
      from: {
        transform: "rotate(0deg)",
      },
      to: {
        transform: "rotate(-360deg)",
        opacity: 0,
      },
    });

    setClosed(true);
    setTimeout(() => {
      setClosed(false);
    }, 500);
  };

  return (
    <Background menuOpened={menuOpened}>
      {menuOpened && (
        <>
          <ButtonSet>
            <CloseButton
              style={props}
              onMouseEnter={() => itemOnMouseEnter(set)}
              onMouseLeave={() => itemOnMouseLeave(set)}
              onClick={() => itemOnClick(set)}
            >
              <X style={{ cursor: "pointer" }} size={40} onClick={closeMenu} />
            </CloseButton>
          </ButtonSet>
          <List>
            <ListItem
              item={0}
              closed={closed}
              onClick={() => {
                setPage(0);
                closeMenu();
              }}
            >
              {t("TOP")}
            </ListItem>
            <ListItem
              item={1}
              closed={closed}
              onClick={() => {
                setPage(1);
                closeMenu();
              }}
            >
              {t("ABOUT")}
            </ListItem>
            <ListItem
              item={2}
              closed={closed}
              onClick={() => {
                setPage(2);
                closeMenu();
              }}
            >
              {t("SKILLS")}
            </ListItem>
            <ListItem
              item={3}
              closed={closed}
              onClick={() => {
                setPage(3);
                closeMenu();
              }}
            >
              {t("WORKS")}
            </ListItem>
            <ListItem
              item={4}
              closed={closed}
              onClick={() => {
                setPage(4);
                closeMenu();
              }}
            >
              {t("CONTACT")}
            </ListItem>
          </List>
          <Footer>
            <FooterContent closed={closed}>
              &copy; {new Date().getFullYear()} Minho Yun
            </FooterContent>
          </Footer>
        </>
      )}
    </Background>
  );
};

export default Menu;
