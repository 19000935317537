import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import UseAnimations from "react-useanimations";
import arrowUp from "react-useanimations/lib/arrowUp";
import arrowDown from "react-useanimations/lib/arrowDown";

const FooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 5rem;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ScrollContainer = styled.div`
  width: 5rem;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ScrollItem = styled.div`
  width: 5rem;
  height: calc(5rem / 3);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: white;
`;

const Footer = ({ page }) => {
  const isMobile = "ontouchstart" in window || navigator.msMaxTouchPoints;

  const { t } = useTranslation();

  return (
    <FooterContainer>
      <ScrollContainer>
        <ScrollItem>
          {page !== 0 && !isMobile && (
            <UseAnimations
              animation={arrowUp}
              strokeColor={"white"}
              loop={true}
              size={22}
              speed={2}
            />
          )}
          {page !== 4 && isMobile && (
            <UseAnimations
              animation={arrowUp}
              strokeColor={"white"}
              loop={true}
              size={22}
              speed={2}
            />
          )}
        </ScrollItem>
        <ScrollItem>
          <Text>{isMobile ? t("SWIPE") : t("SCROLL")}</Text>
        </ScrollItem>
        <ScrollItem>
          {page !== 4 && !isMobile && (
            <UseAnimations
              animation={arrowDown}
              strokeColor={"white"}
              loop={true}
              size={22}
              speed={2}
            />
          )}
          {page !== 0 && isMobile && (
            <UseAnimations
              animation={arrowDown}
              strokeColor={"white"}
              loop={true}
              size={22}
              speed={2}
            />
          )}
        </ScrollItem>
      </ScrollContainer>
    </FooterContainer>
  );
};

export default Footer;
