import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Octokit } from "@octokit/rest";
import { useTranslation } from "react-i18next";
import GlobalStyles from "../components/GlobalStyles";
import Header from "./Header";
import Footer from "./Footer";
import LeftContainer from "./LeftContainer";
import MiddleContainer from "./MiddleContainer";
import RightContainer from "./RightContainer";
import Menu from "../components/Menu";
import PageNavigation from "../components/PageNavigation";
import color from "../colors";
import api from "../api";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  overflow: hidden;
`;

const pageInfo = ["top", "about", "skills", "works", "contact"];

function App() {
  const [page, setPage] = useState(0); // 0: top 1: about 2: skills 3: works 4: contact
  const [menuOpened, setMenuOpened] = useState(false);
  const [languages, setLanguages] = useState({});
  const [works, setWorks] = useState();
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  const { i18n } = useTranslation();

  const octokit = new Octokit({
    auth: process.env.REACT_APP_GITHUB,
  });

  const getDataFromRepos = async () => {
    const { data } = await octokit.request("GET /user/repos", {
      visibility: "all",
      per_page: 100,
    });

    let langArr = [];
    for (const repo of data) {
      const { language } = repo;
      if (language !== null) {
        langArr.push(language);
      }
    }

    let langObj = {};
    for (let i = 0; i < langArr.length; i++) {
      langObj[langArr[i]] = langObj[langArr[i]] + 1 || 1;
    }
    setLanguages(langObj);
  };

  useEffect(() => {
    getDataFromRepos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getWorkData = async () => {
    const { data } = await api.works();
    if (data) {
      setWorks(data);
    }
  };

  useEffect(() => {
    getWorkData();
  }, []);

  const changeLanguage = (lang) => i18n.changeLanguage(lang);

  const openMenu = () => {
    setMenuOpened(true);
  };

  const closeMenu = () => {
    setTimeout(() => {
      setMenuOpened(false);
    }, 550);
  };

  const startTouch = (e) => {
    setX(e.changedTouches[0].screenX);
    setY(e.changedTouches[0].screenY);
  };

  const endTouch = (e) => {
    const diffX = e.changedTouches[0].screenX - x;
    const diffY = e.changedTouches[0].screenY - y;
    const ratioX = Math.abs(diffX / diffY);
    const ratioY = Math.abs(diffY / diffX);
    const absDiff = Math.abs(ratioX > ratioY ? diffX : diffY);

    if (absDiff < 30) return;

    if (ratioY > ratioX) {
      if (diffY <= 0) {
        if (page !== 4) setPage(page + 1);
      } else {
        if (page !== 0) setPage(page - 1);
      }
    }
  };

  const movePage = (num) => {
    setPage(num);
  };

  useEffect(() => {
    const checkScrolled = (event) => {
      if (event.deltaY >= 0) {
        if (page !== 4) setPage(page + 1);
      } else {
        if (page !== 0) setPage(page - 1);
      }
    };
    window.addEventListener("mousewheel", checkScrolled);
    return () => window.removeEventListener("mousewheel", checkScrolled);
  }, [page]);

  useEffect(() => {
    window.addEventListener("touchstart", startTouch);
    return () => window.removeEventListener("touchstart", startTouch);
  });

  useEffect(() => {
    window.addEventListener("touchend", endTouch);
    return () => window.removeEventListener("touchend", endTouch);
  });

  useEffect(() => {
    window.location.href = `#${pageInfo[page]}`;
  }, [page]);

  return (
    <div style={{ backgroundColor: color.lightGreen }}>
      <Header openMenu={openMenu} changeLanguage={changeLanguage} />
      <Menu
        menuOpened={menuOpened}
        closeMenu={closeMenu}
        movePage={movePage}
        setPage={setPage}
      />
      <PageNavigation page={page} setPage={setPage} />
      <Container>
        <LeftContainer page={page} languages={languages} />
        <MiddleContainer page={page} />
        <RightContainer page={page} languages={languages} works={works} />
      </Container>
      <Footer page={page} />
      <GlobalStyles />
    </div>
  );
}

export default App;
